
body {
  font-family: "Lato", sans-serif;
}

input[type="checkbox"]:checked ~ label span svg {
  display: inline-flex;
}

.sticky {
  position: fixed;
  z-index: 9999;
  --tw-bg-opacity: 1;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.8;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, backdrop-filter, -webkit-backdrop-filter;
  transition-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
  transition-duration: 150ms;
}

.dark .sticky {
  --tw-bg-opacity: 1;
  background-color: rgba(20, 40, 110, var(--tw-bg-opacity));
  --tw-bg-opacity: 0.75;
}

.sticky {
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px 0 0 rgba(0, 0, 0, 0.1);
}

.sticky .header-logo {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 960px) {
  .sticky .header-logo {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
}

.sticky .menu-scroll.active {
  opacity: 0.7;
}

.navbarTogglerActive > span:nth-child(1) {
  top: 7px;
  --tw-rotate: 45deg;
  transform: var(--tw-transform);
  transform: var(--tw-transform);
}

.navbarTogglerActive > span:nth-child(2) {
  opacity: 0;
}

.navbarTogglerActive > span:nth-child(3) {
  top: -8px;
  --tw-rotate: 135deg;
  transform: var(--tw-transform);
}

input#togglePlan:checked ~ .dot {
  --tw-translate-x: 100%;
  transform: var(--tw-transform);
}

input#checkboxLabel:checked ~ .box span {
  opacity: 1;
}

.after\:absolute::after {
  content: "";
  position: absolute;
}

.after\:right-1::after {
  content: "";
  right: 0.25rem;
}

.after\:top-1\/2::after {
  content: "";
  top: 50%;
}

.after\:mt-\[-2px\]::after {
  content: "";
  margin-top: -2px;
}

.after\:h-2::after {
  content: "";
  height: 0.5rem;
}

.after\:w-2::after {
  content: "";
  width: 0.5rem;
}

.after\:translate-y-\[-50\%\]::after {
  content: "";
  --tw-translate-y: -50%;
  transform: var(--tw-transform);
}

.after\:rotate-45::after {
  content: "";
  --tw-rotate: 45deg;
  transform: var(--tw-transform);
}

.after\:border-b-2::after {
  content: "";
  border-bottom-width: 2px;
}

.after\:border-r-2::after {
  content: "";
  border-right-width: 2px;
}

.after\:border-current::after {
  content: "";
  border-color: currentColor;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 108, 247, var(--tw-bg-opacity));
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: 0.9;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: 0.8;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 108, 247, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:opacity-70:hover {
  opacity: 0.7;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:shadow-signUp:hover {
  --tw-shadow: 0px 5px 10px rgba(4, 10, 34, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-filter);
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgba(74, 108, 247, var(--tw-border-opacity));
}

.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:shadow-none:focus-visible {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:opacity-70 {
  opacity: 0.7;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.dark .dark\:block {
  display: block;
}

.dark .dark\:hidden {
  display: none;
}

.dark .dark\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dark .dark\:border-opacity-10 {
  --tw-border-opacity: 0.1;
}

.dark .dark\:border-opacity-25 {
  --tw-border-opacity: 0.25;
}

.dark .dark\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 14, 52, var(--tw-bg-opacity));
}

.dark .dark\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .dark\:bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 33, 68, var(--tw-bg-opacity));
}

.dark .dark\:bg-\[\#242B51\] {
  --tw-bg-opacity: 1;
  background-color: rgba(36, 43, 81, var(--tw-bg-opacity));
}

.dark .dark\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 108, 247, var(--tw-bg-opacity));
}

.dark .dark\:bg-\[\#1D2144\] {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 33, 68, var(--tw-bg-opacity));
}

.dark .dark\:bg-opacity-5 {
  --tw-bg-opacity: 0.05;
}

.dark .dark\:bg-opacity-10 {
  --tw-bg-opacity: 0.1;
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:text-body-color {
  --tw-text-opacity: 1;
  color: rgba(149, 156, 177, var(--tw-text-opacity));
}

.dark .dark\:opacity-90 {
  opacity: 0.9;
}

.dark .dark\:opacity-60 {
  opacity: 0.6;
}

.dark .dark\:shadow-signUp {
  --tw-shadow: 0px 5px 10px rgba(4, 10, 34, 0.2);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark .dark\:hover\:bg-opacity-20:hover {
  --tw-bg-opacity: 0.2;
}

.dark .dark\:hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 108, 247, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:hover\:opacity-100:hover {
  opacity: 1;
}

@media (min-width: 540px) {
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:ml-3 {
    margin-left: 0.75rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-11 {
    padding: 2.75rem;
  }

  .sm\:p-\[55px\] {
    padding: 55px;
  }

  .sm\:p-\[60px\] {
    padding: 60px;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 720px) {
  .md\:mb-0 {
    margin-bottom: 0px;
  }

  .md\:block {
    display: block;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-8\/12 {
    width: 66.666667%;
  }

  .md\:w-4\/12 {
    width: 33.333333%;
  }

  .md\:w-2\/3 {
    width: 66.666667%;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-\[40px\] {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:px-\[50px\] {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md\:pt-\[150px\] {
    padding-top: 150px;
  }

  .md\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .md\:text-\[45px\] {
    font-size: 45px;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 960px) {
  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:top-\[110\%\] {
    top: 110%;
  }

  .lg\:mr-0 {
    margin-right: 0px;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0px;
  }

  .lg\:ml-0 {
    margin-left: 0px;
  }

  .lg\:mt-0 {
    margin-top: 0px;
  }

  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-4\/12 {
    width: 33.333333%;
  }

  .lg\:w-2\/12 {
    width: 16.666667%;
  }

  .lg\:w-7\/12 {
    width: 58.333333%;
  }

  .lg\:w-5\/12 {
    width: 41.666667%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.666667%;
  }

  .lg\:w-1\/3 {
    width: 33.333333%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-\[130px\] {
    max-width: 130px;
  }

  .lg\:bg-transparent {
    background-color: transparent;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-11 {
    padding: 2.75rem;
  }

  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:pl-0 {
    padding-left: 0px;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-0 {
    padding-right: 0px;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:after\:right-0::after {
    content: "";
    right: 0px;
  }

  .group:hover .lg\:group-hover\:visible {
    visibility: visible;
  }

  .group:hover .lg\:group-hover\:top-full {
    top: 100%;
  }

  .dark .lg\:dark\:bg-transparent {
    background-color: transparent;
  }
}

@media (min-width: 1140px) {
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:mr-3 {
    margin-right: 0.75rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0px;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-5\/12 {
    width: 41.666667%;
  }

  .xl\:w-2\/12 {
    width: 16.666667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.333333%;
  }

  .xl\:max-w-\[150px\] {
    max-width: 150px;
  }

  .xl\:p-9 {
    padding: 2.25rem;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-\[55px\] {
    padding: 55px;
  }

  .xl\:p-\[50px\] {
    padding: 50px;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:pr-3 {
    padding-right: 0.75rem;
  }

  .xl\:pt-\[180px\] {
    padding-top: 180px;
  }

  .xl\:pb-\[160px\] {
    padding-bottom: 160px;
  }

  .xl\:text-\[45px\] {
    font-size: 45px;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 1320px) {
  .\32xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .\32xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .\32xl\:max-w-\[160px\] {
    max-width: 160px;
  }

  .\32xl\:p-8 {
    padding: 2rem;
  }

  .\32xl\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .\32xl\:px-\[70px\] {
    padding-left: 70px;
    padding-right: 70px;
  }

  .\32xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .\32xl\:pt-\[210px\] {
    padding-top: 210px;
  }

  .\32xl\:pb-\[200px\] {
    padding-bottom: 200px;
  }
}

.nws-modal-overlay {
  background: rgba(9, 13, 52, 0.7) !important;
}

.nws-modal-wrapper .nws-modal .modal-left {
  padding-bottom: 0;
}

.footer-link {
  @apply text-base font-medium inline-block text-body-color mb-4 hover:text-blue-400 cursor-pointer outline-none;
}