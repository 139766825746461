@import "https://fonts.googleapis.com/css2?family=Manrope:wght@400;500;600&display=swap";
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

@keyframes fadeInRight {
  from {
    opacity: 0;
    transform: translate3d(20px, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInRight {
  animation-name: fadeInRight;
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 20px, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

.fadeInUp {
  animation-name: fadeInUp;
}

.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

.animated.infinite {
  animation-iteration-count: infinite;
}

.animated.delay-1s {
  animation-delay: 1s;
}

.animated.delay-2s {
  animation-delay: 2s;
}

.animated.delay-3s {
  animation-delay: 3s;
}

.animated.delay-4s {
  animation-delay: 4s;
}

.animated.delay-5s {
  animation-delay: 5s;
}

.animated.fast {
  animation-duration: .8s;
}

.animated.faster {
  animation-duration: .5s;
}

.animated.slow {
  animation-duration: 2s;
}

.animated.slower {
  animation-duration: 3s;
}

@media print, (prefers-reduced-motion: reduce) {
  .animated {
    transition-duration: 1ms !important;
    animation-duration: 1ms !important;
    animation-iteration-count: 1 !important;
  }
}

#near-wallet-selector-modal {
  --backdrop-bg: #26262630;
  --heading-color: #222;
  --text-color: #676767;
  --sidebar-border-color: #ededed;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: transparent;
  --wallet-option-border-color: #a7a7a730;
  --wallet-option-bg-hover: #6494ee3d;
  --wallet-option-outline-color: #6494ee;
  --content-bg: #fafafa;
  --change-path-bg: #ededed;
  --home-button-bg: #ededed;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #ededed;
  --close-button-fill-icon-color: #9f9f9f;
  --spinner-color: #676767;
  --bottom-section: #ececec;
  --mobile-text: #3e3e3e;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(297.59% 179.46% at 91.67% -25%, #aec2ea 0%, #95d1e4 100%);
  --get-wallet-option-bg-color: #f8f9fa;
  --get-wallet-option-bg-color-hover: #6494ee3d;
  --get-wallet-option-border-color: #c1c1c1;
  --get-wallet-outline-color: #6494ee;
  --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
  --secondary-button-bg-color: #4f7cd129;
  --secondary-button-border-color: #4f7cd129;
  --secondary-button-text-color: #4f7cd1;
  --what-wallet-icon-color: #fff;
  --deprecated-icon-bg-color: #d7e2f7;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

#near-wallet-selector-modal .dark-theme {
  --backdrop-bg: #26262630;
  --heading-color: #fff;
  --text-color: #c1c1c1;
  --sidebar-border-color: #313030;
  --selected-wallet-bg: #4f7cd1;
  --selected-wallet-bg-hover: #262626cc;
  --wallet-option-border-color: #a7a7a730;
  --wallet-option-bg-hover: #4f7cd13d;
  --wallet-option-outline-color: #4f7cd1;
  --content-bg: #232323;
  --change-path-bg: #161616;
  --home-button-bg: #313030;
  --confirm-button-bg: #5f8afa;
  --confirm-button-bg-hover: #5aa6ff;
  --error: #db5555;
  --close-button-bg-color: #313030;
  --close-button-fill-icon-color: #c1c1c1;
  --spinner-color: #fff;
  --bottom-section: #131313;
  --mobile-text: #c1c1c1;
  --connected-green: #47e586;
  --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
  --get-wallet-option-bg-color: #0000001f;
  --get-wallet-option-bg-color-hover: #4f7cd13d;
  --get-wallet-option-border-color: #313030;
  --get-wallet-outline-color: #4f7cd1;
  --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
  --secondary-button-bg-color: #4f7cd129;
  --secondary-button-border-color: #4f7cd1;
  --secondary-button-text-color: #fff;
  --what-wallet-icon-color: #568ffc;
  --deprecated-icon-bg-color: #2f394e;
  --deprecated-icon-bg-color-selected: #4f7cd1;
}

@media (prefers-color-scheme: dark) {
  #near-wallet-selector-modal {
    --backdrop-bg: #26262630;
    --heading-color: #fff;
    --text-color: #c1c1c1;
    --sidebar-border-color: #313030;
    --selected-wallet-bg: #4f7cd1;
    --selected-wallet-bg-hover: #262626cc;
    --wallet-option-border-color: #a7a7a730;
    --wallet-option-bg-hover: #4f7cd13d;
    --wallet-option-outline-color: #4f7cd1;
    --content-bg: #232323;
    --change-path-bg: #161616;
    --home-button-bg: #313030;
    --confirm-button-bg: #5f8afa;
    --confirm-button-bg-hover: #5aa6ff;
    --error: #db5555;
    --close-button-bg-color: #313030;
    --close-button-fill-icon-color: #c1c1c1;
    --spinner-color: #fff;
    --bottom-section: #131313;
    --mobile-text: #c1c1c1;
    --connected-green: #47e586;
    --gradient-dark-icon: radial-gradient(124.37% 124.37% at 91.67% -25%, #112140 0%, #262b35 100%);
    --get-wallet-option-bg-color: #0000001f;
    --get-wallet-option-bg-color-hover: #4f7cd13d;
    --get-wallet-option-border-color: #313030;
    --get-wallet-outline-color: #4f7cd1;
    --get-wallet-option-box-shadow: 0px 2px 2px #1116180a;
    --secondary-button-bg-color: #4f7cd129;
    --secondary-button-border-color: #4f7cd1;
    --secondary-button-text-color: #fff;
    --what-wallet-icon-color: #568ffc;
    --deprecated-icon-bg-color: #2f394e;
    --deprecated-icon-bg-color-selected: #4f7cd1;
  }
}

.nws-modal-wrapper {
  opacity: 0;
  visibility: hidden;
  color: var(--wallet-selector-text-color, var(--text-color));
  z-index: 100;
  justify-content: center;
  align-items: center;
  font-family: Manrope, sans-serif;
  font-weight: 500;
  display: flex;
  position: fixed;
  inset: 0;
}

.nws-modal-wrapper .nws-modal-overlay {
  background: var(--wallet-selector-backdrop-bg, var(--backdrop-bg));
  height: 100%;
  width: 100%;
  position: absolute;
}

.nws-modal-wrapper .nws-modal {
  background: var(--wallet-selector-content-bg, var(--content-bg));
  width: 100%;
  max-width: 812px;
  height: 555px;
  background-color: var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 16px;
  font-size: 16px;
  line-height: 1.6;
  transition: visibility linear, opacity .25s, transform .25s;
  display: inline-flex;
  position: absolute;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%);
}

.nws-modal-wrapper .nws-modal * {
  box-sizing: border-box;
}

.nws-modal-wrapper .nws-modal button {
  cursor: pointer;
  border: none;
  padding: .5em 1em;
}

.nws-modal-wrapper .nws-modal .modal-left {
  width: 35%;
  border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
  height: 100%;
  padding: 32px 24px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .modal-left::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper {
  margin-bottom: 20px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
  margin-top: 0;
  margin-bottom: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 10px;
  margin: 0;
  padding: 0;
  list-style-type: none;
  display: grid;
}

.single-wallet {
  width: 100%;
  cursor: pointer;
  flex-flow: row;
  place-content: center space-between;
  align-items: center;
  column-gap: 24px;
  padding: 12px;
  display: flex;
}

.single-wallet:focus-within {
  outline: var(--wallet-option-outline-color) solid 2px;
}

.single-wallet .icon {
  height: 48px;
  width: auto;
}

.single-wallet .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet .content {
  width: 50%;
}

.single-wallet .content .name {
  color: #fff;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.single-wallet .content .description {
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 150%;
}

.single-wallet .button-get {
  margin-left: auto;
  margin-right: 0;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar {
  cursor: pointer;
  border-radius: 8px;
  justify-content: flex-start;
  column-gap: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover {
  background-color: var(--wallet-selector-wallet-option-bg-hover, var(--wallet-option-bg-hover));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  outline: none;
  padding: 12px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet .content .title {
  color: #fff;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .icon, .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet .title {
  opacity: .4;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.deprecated-wallet {
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .warning-triangle {
  background: var(--content-bg);
  border-top-left-radius: 63px;
  border-bottom-left-radius: 10px;
  display: flex;
  position: absolute;
  top: 2px;
  left: 42px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar:hover .warning-triangle {
  background-color: var(--deprecated-icon-bg-color);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.selected-wallet.sidebar .warning-triangle {
  background-color: var(--deprecated-icon-bg-color-selected);
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .icon {
  width: 40px;
  height: 40px;
  background-color: #fff;
  border-radius: 5px;
  padding: 5px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.connected-wallet .icon:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border: 3px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  display: block;
  position: absolute;
  top: -8px;
  right: -8px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar.selected-wallet.connected-wallet .icon:before {
  border: 3px solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content {
  height: 32px;
  width: auto;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  margin-left: 10px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 32px;
}

.nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .description {
  display: none;
}

.nws-modal-wrapper .nws-modal .modal-right {
  width: 65%;
  padding: 32px;
  overflow: auto;
}

.nws-modal-wrapper .nws-modal .modal-right::-webkit-scrollbar {
  width: 10px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
  margin-top: 91px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper-err {
  margin-top: 45px;
  padding: 0 28px;
}

.nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
  grid-template-columns: repeat(auto-fit, minmax(110px, 1fr));
  gap: 10px;
  margin: 20px 0 0;
  padding: 0;
  display: grid;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what {
  width: 100%;
  flex-flow: row;
  place-content: center flex-start;
  align-items: flex-start;
  gap: 32px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what + .wallet-what {
  margin-top: 50px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side {
  width: 56px;
  height: 56px;
  background: var(--gradient-dark-icon);
  color: var(--what-wallet-icon-color);
  border-radius: 12px;
  padding: 8px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .icon-side svg {
  opacity: .7;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side {
  width: 100%;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side h3 {
  color: var(--wallet-selector-text-color, var(--heading-color));
  margin: 0 auto 8px 0;
  font-family: inherit;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .modal-right .wallet-what .content-side p {
  word-break: break-word;
  margin: 0;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin: 4px auto;
  font-size: 18px;
}

.nws-modal-wrapper .nws-modal .modal-right .nws-modal-body.get-wallet-body {
  row-gap: 16px;
  margin-top: 64px;
  padding-left: 32px;
  padding-right: 0;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
  background-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  font: inherit;
  color: #fff;
  border-color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border: .1em solid var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  border-radius: 4px;
  margin: 25px auto 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
  background-color: var(--wallet-selector-home-button-bg, var(--home-button-bg));
  color: #6494ee;
  border-width: 0;
  border-radius: 4px;
  margin: 4px 0;
  padding: 8px 24px;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-header {
  width: 100%;
  flex: 1;
  justify-content: flex-start;
  align-items: flex-start;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
  cursor: pointer;
  height: 32px;
  width: 32px;
  background-color: var(--wallet-selector-close-button-bg-color, var(--close-button-bg-color));
  border: 0;
  border-radius: 50px;
  justify-content: center;
  align-items: center;
  padding: 0;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  fill: var(--wallet-selector-close-button-fill-icon-color, var(--close-button-fill-icon-color));
}

.nws-modal-wrapper .nws-modal .back-button {
  width: 32px;
  height: 32px;
  background: none;
  border: none;
  border-radius: 50%;
  padding: 4px;
  display: block;
}

.nws-modal-wrapper .nws-modal .nws-modal-header-wrapper {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button:active {
  background: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header .close-button svg {
  pointer-events: none;
}

.nws-modal-wrapper .nws-modal .nws-modal-header h2 {
  color: var(--wallet-selector-heading-color, var(--heading-color));
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .action-buttons {
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper {
  display: none;
  position: absolute;
  bottom: 15px;
  right: 50px;
}

.nws-modal-wrapper .nws-modal .lang-selector {
  appearance: none;
  cursor: pointer;
  height: 32px;
  -webkit-user-select: none;
  user-select: none;
  width: 54px;
  z-index: 1;
  text-indent: 54px;
  background: url("data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMzIiIGhlaWdodD0iMzIiIHZpZXdCb3g9IjAgMCAyNCAyNCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTEyLjc1IDE5LjI1TDE2IDEyLjc1TDE5LjI1IDE5LjI1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTE0IDE3LjI1SDE4IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTQuNzUgNi43NUgxMy4yNSIgc3Ryb2tlPSIjQzFDMUMxIiBzdHJva2Utd2lkdGg9IjEuNSIgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIiBzdHJva2UtbGluZWpvaW49InJvdW5kIi8+CjxwYXRoIGQ9Ik05IDYuNVY0Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPHBhdGggZD0iTTExLjI1IDYuNzVDMTEuMjUgNi43NSAxMS4yNSA5LjI1IDkuMjUgMTEuMjVDNy4yNSAxMy4yNSA0Ljc1IDEzLjI1IDQuNzUgMTMuMjUiIHN0cm9rZT0iI0MxQzFDMSIgc3Ryb2tlLXdpZHRoPSIxLjUiIHN0cm9rZS1saW5lY2FwPSJyb3VuZCIgc3Ryb2tlLWxpbmVqb2luPSJyb3VuZCIvPgo8cGF0aCBkPSJNMTIuMjUgMTMuMjVDMTIuMjUgMTMuMjUgOS43NSAxMy4yNSA3Ljc1IDExLjI1QzcuMzQ1NTEgMTAuODQ1NSA2Ljc1IDkuNzUgNi43NSA5Ljc1IiBzdHJva2U9IiNDMUMxQzEiIHN0cm9rZS13aWR0aD0iMS41IiBzdHJva2UtbGluZWNhcD0icm91bmQiIHN0cm9rZS1saW5lam9pbj0icm91bmQiLz4KPC9zdmc+Cg==") 5px / 20px 20px no-repeat;
  border: 0;
  outline: none;
  padding-right: 54px;
  font-size: 16px;
  position: relative;
}

.nws-modal-wrapper .nws-modal .lang-selector-wrapper:after {
  content: "";
  height: 7px;
  width: 7px;
  border: 2px solid #72727a;
  border-width: 2px 2px 0 0;
  display: inline-block;
  position: absolute;
  top: calc(50% - 10px);
  right: 10px;
  transform: rotate(135deg)translateY(-50%);
}

.nws-modal-wrapper .nws-modal .switch-network-message-wrapper .content .network-id {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper {
  padding: 0 26px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
  justify-content: center;
  margin-top: 53px;
  margin-bottom: 40px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description p {
  margin: 14px 0;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description .specify-path {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
  cursor: pointer;
}

.nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list {
  flex-direction: column;
  justify-content: center;
  margin-bottom: 16px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
  justify-content: center;
  margin-top: 91px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .display-path {
  background: var(--home-button-bg);
  color: #606060;
  letter-spacing: 1px;
  border-radius: 8px;
  padding: 16px;
  font-weight: 700;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path {
  background: var(--change-path-bg);
  width: 63px;
  border-radius: 8px;
  justify-content: space-between;
  align-items: center;
  margin-left: 10px;
  padding: 0 5px;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .path-value {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper p {
  margin: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .path-description {
  text-align: center;
  margin-top: 24px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link {
  text-align: center;
  color: var(--selected-wallet-bg);
  cursor: pointer;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .specify-path-wrapper .what-link a {
  color: var(--selected-wallet-bg);
  font-size: 14px;
  text-decoration: none;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper {
  flex-direction: column;
  display: flex;
}

.nws-modal-wrapper .specify-path-wrapper .change-path-wrapper .change-path .buttons-wrapper button {
  width: 23px;
  background-color: var(--home-button-bg);
  border: none;
  padding: 0;
}

.nws-modal-wrapper .nws-modal .no-accounts-found-wrapper {
  margin-top: 50px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account {
  margin-top: 20px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper {
  justify-content: center;
  margin-top: 90px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .enter-custom-account .input-wrapper input {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  border: 1px solid var(--confirm-button-bg-hover);
  border-radius: 50px;
  padding: 6px 8px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper p {
  text-align: center;
  margin-top: 20px;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper {
  justify-content: center;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .button-wrapper button {
  color: var(--selected-wallet-bg);
  border: 1px solid var(--selected-wallet-bg);
  width: 78px;
  background-color: #0000;
  border-radius: 20px;
  padding: 6px 8px;
  font-size: 14px;
  font-weight: 600;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .form {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
  color: var(--text-color);
  flex-direction: column;
  margin-bottom: 16px;
  padding: 10px;
  display: flex;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control .account input[type="checkbox"] {
  width: 25px;
  -webkit-transform: scale(2);
  -moz-transform: scale(2);
  -ms-transform: scale(2);
  -o-transform: scale(2);
  padding: 10px;
  transform: scale(1.7);
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control label {
  color: inherit;
}

.nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .action-buttons {
  justify-content: flex-end;
}

.nws-modal-wrapper .nws-modal .overview-wrapper p {
  text-align: center;
  font-size: 14px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .accounts {
  margin-top: 96px;
}

.nws-modal-wrapper .nws-modal .overview-wrapper .account {
  border-bottom: 1px solid var(--sidebar-border-color);
  color: var(--wallet-selector-heading-color, var(--heading-color));
  padding: 16px 0;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  flex-direction: column;
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .refresh-link {
  color: #5f8afa;
  cursor: pointer;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data {
  align-items: center;
  display: flex;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data p {
  margin: 0 0 0 10px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper p {
  margin: 16px 0;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box {
  width: 40px;
  height: 40px;
}

.nws-modal-wrapper .wallet-not-installed-wrapper .wallet-data .wallet-icon-box img {
  width: 100%;
  height: auto;
}

.open {
  opacity: 1;
  visibility: visible;
  transition: visibility linear, opacity .25s;
}

.nws-modal-wrapper .spinner {
  --size: 160px;
  --border: 6px;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  display: flex;
  position: relative;
}

.nws-modal-wrapper .spinner .icon {
  width: calc(var(--size) / 1.2);
  height: calc(var(--size) / 1.2);
  border: 1px solid #0000000d;
  border-radius: 50%;
  box-shadow: 0 10px 20px #0000000d;
}

.nws-modal-wrapper .spinner img {
  width: 100%;
  height: auto;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .nws-modal-wrapper .nws-modal-wrapper .nws-modal {
    width: 250px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .derivation-path-wrapper .derivation-path-list input {
    max-width: 140px;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control {
    flex-direction: column;
  }

  .nws-modal-wrapper .nws-modal-wrapper .nws-modal .choose-ledger-account-form-wrapper .nws-form-control select {
    text-align: center;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper {
    grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  }
}

.nws-modal-wrapper .nws-modal-wrapper.dark-theme .spinner .icon {
  box-shadow: 0 10px 20px #ffffff0d;
}

@media (prefers-color-scheme: dark) {
  .nws-modal-wrapper .spinner .icon {
    box-shadow: 0 10px 20px #ffffff0d;
  }
}

@keyframes outAnimation {
  0% {
    opacity: 1;
  }

  100% {
    opacity: 0;
    visibility: hidden;
  }
}

.connecting-wrapper {
  flex-flow: column;
  justify-content: flex-start;
  align-items: center;
  display: flex;
}

.connecting-wrapper .content .icon {
  width: 80px;
  height: 80px;
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  position: relative;
}

.connecting-wrapper .content .icon .green-dot {
  width: 30px;
  height: 30px;
  background-color: var(--connected-green);
  border: 5px solid var(--wallet-selector-content-bg, var(--content-bg));
  border-radius: 50%;
  position: absolute;
  top: -10px;
  right: -10px;
}

.connecting-wrapper .content .icon img {
  width: 100%;
  height: auto;
}

.connecting-wrapper .content h3 {
  margin: 16px 0;
}

.connecting-wrapper .content .connecting-name {
  text-align: center;
  color: var(--wallet-selector-heading-color, var(--heading-color));
  align-items: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-message {
  margin-top: 16px;
  font-size: 14px;
}

.connecting-wrapper .content .connecting-details {
  align-items: center;
  margin-top: 40px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  display: flex;
}

.connecting-wrapper .content .connecting-details span {
  color: var(--wallet-selector-selected-wallet-bg, var(--selected-wallet-bg));
}

.connecting-wrapper .content .connecting-details .spinner {
  width: 25px;
  height: auto;
  animation: 2s linear infinite spinner;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection button {
  background: var(--secondary-button-bg-color);
  border: 1px solid var(--secondary-button-border-color);
  color: var(--secondary-button-text-color);
  border-radius: 4px;
  gap: 8px;
  padding: 10px 24px;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper {
  vertical-align: middle;
  text-align: center;
  flex-flow: column;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error {
  color: #ce5a6f;
  vertical-align: middle;
  text-align: center;
  flex-flow: row;
  justify-content: center;
  align-items: center;
  column-gap: 12px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 150%;
  display: flex;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper .error svg {
  color: var(--wallet-selector-content-bg, var(--content-bg));
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .error-wrapper p {
  word-break: break-word;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  margin: 14px 0;
  display: -webkit-box;
  overflow: hidden;
}

.nws-modal-wrapper .nws-modal .nws-modal-body .alert-message .connection .success {
  color: #4fd98f;
}

.nws-modal-wrapper .nws-modal .connecting-wrapper .content {
  padding: 25px;
}

.connecting-wrapper .content .connecting-details {
  margin-top: 20px;
}

.connecting-wrapper .wallet-connected-success {
  align-items: center;
  display: flex;
}

.connecting-wrapper .wallet-connected-success svg {
  color: var(--content-bg);
}

.connecting-wrapper .wallet-connected-success span {
  color: var(--wallet-selector-connected-green, var(--connected-green));
  margin-left: 10px;
  font-size: 14px;
}

.single-wallet-get {
  width: 100%;
  height: 100%;
  border: 1px solid var(--get-wallet-option-border-color);
  box-shadow: var(--get-wallet-option-box-shadow);
  background-color: var(--get-wallet-option-bg-color);
  cursor: pointer;
  border-radius: 8px;
  flex-flow: column wrap;
  flex-grow: 1;
  place-content: center space-between;
  align-items: center;
  margin: 0 auto auto;
  padding: 14px;
  display: flex;
  position: relative;
}

.single-wallet-get:hover {
  background: var(--get-wallet-option-bg-color-hover);
}

.single-wallet-get:focus-within {
  outline: var(--get-wallet-outline-color) solid 2px;
}

.single-wallet-get .icon {
  min-width: 32px;
  max-height: 32px;
  width: 32px;
  height: 32px;
  background-color: #fff;
  border-radius: 5px;
  margin-bottom: 8px;
  padding: 5px;
  position: relative;
}

.single-wallet-get .icon img {
  width: 100%;
  height: auto;
  max-width: 48px;
}

.single-wallet-get .title {
  color: var(--heading-color);
  text-align: center;
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
}

.single-wallet-get .type {
  color: var(--text-color);
  text-align: center;
  margin-top: 0;
  font-size: 10px;
  font-weight: 400;
  line-height: 12px;
}

.single-wallet-get .small-icon {
  position: absolute;
  top: 8px;
  right: 8px;
}

.single-wallet-get .small-icon svg {
  color: #4c5155;
}

.single-wallet-get:hover .small-icon svg {
  color: #4f7cd1;
}

.single-wallet-get .description {
  align-items: center;
  font-family: inherit;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}

.single-wallet-get .button-get {
  margin-left: auto;
  margin-right: 0;
}

.connected-flag {
  color: var(--connected-green);
  border: 1px solid var(--connected-green);
  border-radius: 50px;
  align-items: center;
  padding: 3px 10px;
  display: flex;
}

.connected-flag:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--connected-green);
  border-radius: 50%;
  margin-right: 5px;
  display: block;
}

.scan-qr-code {
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  display: flex;
}

.scan-qr-code .qr-code > div:first-of-type {
  background: #fff;
  border-radius: 16px;
  margin-bottom: 20px;
  padding: 20px;
}

.scan-qr-code .qr-code {
  height: calc(100% - 200px);
  border: 1px solid var(--wallet-selector-content-bg, var(--content-bg));
  text-align: center;
  border-radius: 4px;
  margin-top: 64px;
}

.scan-qr-code .qr-code svg {
  width: 239px;
  height: 239px;
}

.scan-qr-code .qr-code .copy-btn {
  cursor: pointer;
  text-align: center;
  color: var(--selected-wallet-bg);
  justify-content: center;
  align-items: center;
  font-size: 14px;
  display: flex;
}

.scan-qr-code .qr-code .copy-btn svg {
  width: 24px;
  height: 24px;
  margin-right: 5px;
}

.scan-qr-code .qr-code .notification {
  font-size: 14px;
}

.scan-qr-code .footer {
  justify-content: space-between;
  align-items: center;
  gap: 24px;
  padding: 24px;
  font-size: 14px;
  display: flex;
  position: relative;
  bottom: 0;
}

.scan-qr-code .footer .btn {
  background: var(--secondary-button-bg-color);
  color: var(--secondary-button-text-color);
  border: 1px solid var(--secondary-button-border-color);
  border-radius: 4px;
  padding: 8px 24px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

@media (min-width: 577px) {
  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile {
    display: none;
  }

  .scan-qr-code .footer {
    position: absolute;
  }
}

@media (min-width: 769px) {
  .button-spacing {
    margin: 90px;
  }
}

@media (max-width: 768px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    margin-top: 45px;
  }

  .button-spacing {
    margin: 45px;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 40%;
    border-right: 1px solid var(--wallet-selector-sidebar-border-color, var(--sidebar-border-color));
    height: 100%;
    max-height: 70vh;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 60%;
    padding: 32px 16px;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper {
    padding: 0 0 0 10px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image {
    margin-top: 30px;
    margin-bottom: 35px;
  }
}

@media (max-width: 576px) {
  .nws-modal-wrapper .nws-modal .wallet-home-wrapper .get-wallet-wrapper, .nws-modal-wrapper .nws-modal .wallet-home-wrapper .wallet-info-wrapper, .nws-modal-wrapper .nws-modal .connecting-wrapper, .nws-modal-wrapper .nws-modal .wallet-not-installed-wrapper, .nws-modal-wrapper .nws-modal .switch-network-message-wrapper {
    margin-top: 20px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .modal-left-title h2 {
    text-align: center;
  }

  .nws-modal-wrapper .wallet-not-installed-wrapper > p {
    max-width: 500px;
    margin: 20px 0 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.get-wallet {
    background-color: var(--wallet-selector-home-button-bg, var(--content-bg));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-hide {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .what-wallet-mobile p {
    text-align: center;
    margin: auto;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal {
    width: 100%;
    height: 500px;
    background: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
    border-radius: 16px 16px 0 0;
    display: block;
    bottom: 0;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left {
    width: 100%;
    background-color: var(--wallet-selector-content-bg, var(--content-bg));
    height: auto;
    padding: 32px 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body {
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-left .nws-modal-body .wallet-options-wrapper {
    margin: auto;
  }

  .nws-modal-wrapper .nws-modal .modal-right {
    width: 100%;
    background-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .connecting-wrapper .content {
    padding-top: 0;
  }

  .connecting-wrapper .content .icon .green-dot {
    border-color: var(--wallet-selector-mobile-bottom-section, var(--bottom-section));
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle {
    text-align: center;
    margin: 4px auto;
    font-size: 16px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-header h3.middleTitle.-open {
    padding-right: 32px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body .content {
    text-align: center;
    color: var(--mobile-text);
    margin: 0;
    font-size: 14px;
  }

  .nws-modal-wrapper .nws-modal .modal-right .nws-modal-body {
    margin-top: 10px;
    padding: 0;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-body button.middleButton {
    margin: 25px auto 12px;
  }

  .nws-modal-wrapper .nws-modal .modal-header {
    text-align: center;
    font-size: 18px;
    display: block;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header .close-button {
    position: absolute;
    top: 30px;
    right: 30px;
  }

  .nws-modal-wrapper .nws-modal .nws-modal-header h2 {
    text-align: center;
    font-size: 18px;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper {
    margin-bottom: 0;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .description {
    display: none;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list {
    grid-gap: 0;
    gap: 0;
    padding-block: 10px;
    display: flex;
    overflow: auto;
  }

  .nws-modal-wrapper .nws-modal .info {
    width: 90px;
    display: none;
  }

  .single-wallet {
    min-width: 76px;
    max-width: 76px;
    display: block;
  }

  .single-wallet.sidebar .icon {
    width: 56px;
    height: 56px;
    margin: auto;
  }

  .single-wallet.sidebar .content {
    width: auto;
  }

  .nws-modal-wrapper .nws-modal .wallet-options-wrapper .options-list .single-wallet.sidebar .content .title {
    text-align: center;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    margin-top: 10px;
    font-size: 12px;
    line-height: 16px;
    display: -webkit-box;
    overflow: hidden;
    margin-left: 0 !important;
  }

  .single-wallet.wallet-connect, .single-wallet.my-near-wallet {
    word-break: break-word;
  }

  .single-wallet-get:hover {
    background-color: #4f7cd129;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-image, .nws-modal-wrapper .specify-path-wrapper .change-path-wrapper {
    margin-top: 30px;
    margin-bottom: 30px;
  }

  .nws-modal-wrapper .nws-modal .derivation-path-wrapper .enter-derivation-path .ledger-description > p {
    max-width: 450px;
    margin-left: auto;
    margin-right: auto;
  }

  .nws-modal-wrapper ::-webkit-scrollbar {
    height: 4px;
    width: 4px;
    background: var(--backdrop-bg);
  }

  .nws-modal-wrapper ::-webkit-scrollbar-thumb:horizontal {
    background: var(--close-button-fill-icon-color);
    border-radius: 10px;
  }
}

body {
  font-family: Lato, sans-serif;
}

input[type="checkbox"]:checked ~ label span svg {
  display: inline-flex;
}

.sticky {
  z-index: 9999;
  background-color: rgba(0, 0, 0, var(--tw-bg-opacity));
  --tw-bg-opacity: .8;
  transition-property: background-color, border-color, color, fill, stroke, opacity, box-shadow, transform, filter, -webkit-backdrop-filter, backdrop-filter, -webkit-backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
  position: fixed;
}

.dark .sticky {
  background-color: rgba(20, 40, 110, var(--tw-bg-opacity));
  --tw-bg-opacity: .75;
}

.sticky {
  -webkit-backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
  box-shadow: inset 0 -1px #0000001a;
}

.sticky .header-logo {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

@media (min-width: 960px) {
  .sticky .header-logo {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }
}

.sticky .menu-scroll.active {
  opacity: .7;
}

.navbarTogglerActive > span:nth-child(1) {
  --tw-rotate: 45deg;
  transform: var(--tw-transform);
  top: 7px;
}

.navbarTogglerActive > span:nth-child(2) {
  opacity: 0;
}

.navbarTogglerActive > span:nth-child(3) {
  --tw-rotate: 135deg;
  transform: var(--tw-transform);
  top: -8px;
}

input#togglePlan:checked ~ .dot {
  --tw-translate-x: 100%;
  transform: var(--tw-transform);
}

input#checkboxLabel:checked ~ .box span {
  opacity: 1;
}

.after\:absolute:after {
  content: "";
  position: absolute;
}

.after\:right-1:after {
  content: "";
  right: .25rem;
}

.after\:top-1\/2:after {
  content: "";
  top: 50%;
}

.after\:mt-\[-2px\]:after {
  content: "";
  margin-top: -2px;
}

.after\:h-2:after {
  content: "";
  height: .5rem;
}

.after\:w-2:after {
  content: "";
  width: .5rem;
}

.after\:translate-y-\[-50\%\]:after {
  content: "";
  --tw-translate-y: -50%;
  transform: var(--tw-transform);
}

.after\:rotate-45:after {
  content: "";
  --tw-rotate: 45deg;
  transform: var(--tw-transform);
}

.after\:border-b-2:after {
  content: "";
  border-bottom-width: 2px;
}

.after\:border-r-2:after {
  content: "";
  border-right-width: 2px;
}

.after\:border-current:after {
  content: "";
  border-color: currentColor;
}

.hover\:bg-white:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.hover\:bg-primary:hover {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 108, 247, var(--tw-bg-opacity));
}

.hover\:bg-opacity-90:hover {
  --tw-bg-opacity: .9;
}

.hover\:bg-opacity-80:hover {
  --tw-bg-opacity: .8;
}

.hover\:bg-opacity-100:hover {
  --tw-bg-opacity: 1;
}

.hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 108, 247, var(--tw-text-opacity));
}

.hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.hover\:underline:hover {
  text-decoration: underline;
}

.hover\:opacity-70:hover {
  opacity: .7;
}

.hover\:opacity-100:hover {
  opacity: 1;
}

.hover\:shadow-signUp:hover {
  --tw-shadow: 0px 5px 10px #040a2233;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.hover\:grayscale-0:hover {
  --tw-grayscale: grayscale(0);
  filter: var(--tw-filter);
}

.focus\:border-primary:focus {
  --tw-border-opacity: 1;
  border-color: rgba(74, 108, 247, var(--tw-border-opacity));
}

.focus\:border-opacity-100:focus {
  --tw-border-opacity: 1;
}

.focus\:ring-2:focus {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 #0000);
}

.focus-visible\:shadow-none:focus-visible {
  --tw-shadow: 0 0 #0000;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.group:hover .group-hover\:opacity-70 {
  opacity: .7;
}

.group:hover .group-hover\:opacity-100 {
  opacity: 1;
}

.dark .dark\:block {
  display: block;
}

.dark .dark\:hidden {
  display: none;
}

.dark .dark\:border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.dark .dark\:border-opacity-10 {
  --tw-border-opacity: .1;
}

.dark .dark\:border-opacity-25 {
  --tw-border-opacity: .25;
}

.dark .dark\:bg-black {
  --tw-bg-opacity: 1;
  background-color: rgba(9, 14, 52, var(--tw-bg-opacity));
}

.dark .dark\:bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.dark .dark\:bg-dark {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 33, 68, var(--tw-bg-opacity));
}

.dark .dark\:bg-\[\#242B51\] {
  --tw-bg-opacity: 1;
  background-color: rgba(36, 43, 81, var(--tw-bg-opacity));
}

.dark .dark\:bg-primary {
  --tw-bg-opacity: 1;
  background-color: rgba(74, 108, 247, var(--tw-bg-opacity));
}

.dark .dark\:bg-\[\#1D2144\] {
  --tw-bg-opacity: 1;
  background-color: rgba(29, 33, 68, var(--tw-bg-opacity));
}

.dark .dark\:bg-opacity-5 {
  --tw-bg-opacity: .05;
}

.dark .dark\:bg-opacity-10 {
  --tw-bg-opacity: .1;
}

.dark .dark\:text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:text-body-color {
  --tw-text-opacity: 1;
  color: rgba(149, 156, 177, var(--tw-text-opacity));
}

.dark .dark\:opacity-90 {
  opacity: .9;
}

.dark .dark\:opacity-60 {
  opacity: .6;
}

.dark .dark\:shadow-signUp {
  --tw-shadow: 0px 5px 10px #040a2233;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

.dark .dark\:hover\:bg-opacity-20:hover {
  --tw-bg-opacity: .2;
}

.dark .dark\:hover\:text-primary:hover {
  --tw-text-opacity: 1;
  color: rgba(74, 108, 247, var(--tw-text-opacity));
}

.dark .dark\:hover\:text-white:hover {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.dark .dark\:hover\:opacity-100:hover {
  opacity: 1;
}

@media (min-width: 540px) {
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }

  .sm\:ml-3 {
    margin-left: .75rem;
  }

  .sm\:block {
    display: block;
  }

  .sm\:flex {
    display: flex;
  }

  .sm\:w-1\/2 {
    width: 50%;
  }

  .sm\:justify-end {
    justify-content: flex-end;
  }

  .sm\:p-8 {
    padding: 2rem;
  }

  .sm\:p-11 {
    padding: 2.75rem;
  }

  .sm\:p-\[55px\] {
    padding: 55px;
  }

  .sm\:p-\[60px\] {
    padding: 60px;
  }

  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }

  .sm\:text-right {
    text-align: right;
  }

  .sm\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .sm\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .sm\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .sm\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .sm\:leading-relaxed {
    line-height: 1.625;
  }

  .sm\:leading-tight {
    line-height: 1.25;
  }
}

@media (min-width: 720px) {
  .md\:mb-0 {
    margin-bottom: 0;
  }

  .md\:block {
    display: block;
  }

  .md\:h-14 {
    height: 3.5rem;
  }

  .md\:h-6 {
    height: 1.5rem;
  }

  .md\:w-14 {
    width: 3.5rem;
  }

  .md\:w-6 {
    width: 1.5rem;
  }

  .md\:w-1\/2 {
    width: 50%;
  }

  .md\:w-8\/12 {
    width: 66.6667%;
  }

  .md\:w-4\/12 {
    width: 33.3333%;
  }

  .md\:w-2\/3 {
    width: 66.6667%;
  }

  .md\:justify-end {
    justify-content: flex-end;
  }

  .md\:p-9 {
    padding: 2.25rem;
  }

  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:py-\[40px\] {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .md\:px-\[50px\] {
    padding-left: 50px;
    padding-right: 50px;
  }

  .md\:pt-\[150px\] {
    padding-top: 150px;
  }

  .md\:pb-\[120px\] {
    padding-bottom: 120px;
  }

  .md\:text-\[45px\] {
    font-size: 45px;
  }

  .md\:text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .md\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .md\:leading-tight {
    line-height: 1.25;
  }

  .md\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 960px) {
  .lg\:invisible {
    visibility: hidden;
  }

  .lg\:static {
    position: static;
  }

  .lg\:absolute {
    position: absolute;
  }

  .lg\:top-\[110\%\] {
    top: 110%;
  }

  .lg\:mr-0 {
    margin-right: 0;
  }

  .lg\:ml-8 {
    margin-left: 2rem;
  }

  .lg\:mb-0 {
    margin-bottom: 0;
  }

  .lg\:ml-0 {
    margin-left: 0;
  }

  .lg\:mt-0 {
    margin-top: 0;
  }

  .lg\:mb-3 {
    margin-bottom: .75rem;
  }

  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }

  .lg\:block {
    display: block;
  }

  .lg\:flex {
    display: flex;
  }

  .lg\:inline-flex {
    display: inline-flex;
  }

  .lg\:hidden {
    display: none;
  }

  .lg\:w-full {
    width: 100%;
  }

  .lg\:w-4\/12 {
    width: 33.3333%;
  }

  .lg\:w-2\/12 {
    width: 16.6667%;
  }

  .lg\:w-7\/12 {
    width: 58.3333%;
  }

  .lg\:w-5\/12 {
    width: 41.6667%;
  }

  .lg\:w-1\/2 {
    width: 50%;
  }

  .lg\:w-8\/12 {
    width: 66.6667%;
  }

  .lg\:w-1\/3 {
    width: 33.3333%;
  }

  .lg\:max-w-full {
    max-width: 100%;
  }

  .lg\:max-w-\[130px\] {
    max-width: 130px;
  }

  .lg\:bg-transparent {
    background-color: #0000;
  }

  .lg\:p-8 {
    padding: 2rem;
  }

  .lg\:p-11 {
    padding: 2.75rem;
  }

  .lg\:py-0 {
    padding-top: 0;
    padding-bottom: 0;
  }

  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }

  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }

  .lg\:px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .lg\:pl-0 {
    padding-left: 0;
  }

  .lg\:pr-4 {
    padding-right: 1rem;
  }

  .lg\:pr-0 {
    padding-right: 0;
  }

  .lg\:text-left {
    text-align: left;
  }

  .lg\:text-right {
    text-align: right;
  }

  .lg\:text-4xl {
    font-size: 2.25rem;
    line-height: 2.5rem;
  }

  .lg\:text-xl {
    font-size: 1.25rem;
    line-height: 1.75rem;
  }

  .lg\:text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .lg\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .lg\:leading-tight {
    line-height: 1.25;
  }

  .lg\:leading-relaxed {
    line-height: 1.625;
  }

  .lg\:opacity-0 {
    opacity: 0;
  }

  .lg\:shadow-none {
    --tw-shadow: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:shadow-lg {
    --tw-shadow: 0 10px 15px -3px #0000001a, 0 4px 6px -2px #0000000d;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
  }

  .lg\:after\:right-0:after {
    content: "";
    right: 0;
  }

  .group:hover .lg\:group-hover\:visible {
    visibility: visible;
  }

  .group:hover .lg\:group-hover\:top-full {
    top: 100%;
  }

  .dark .lg\:dark\:bg-transparent {
    background-color: #0000;
  }
}

@media (min-width: 1140px) {
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }

  .xl\:ml-12 {
    margin-left: 3rem;
  }

  .xl\:mr-3 {
    margin-right: .75rem;
  }

  .xl\:mb-0 {
    margin-bottom: 0;
  }

  .xl\:flex {
    display: flex;
  }

  .xl\:w-5\/12 {
    width: 41.6667%;
  }

  .xl\:w-2\/12 {
    width: 16.6667%;
  }

  .xl\:w-3\/12 {
    width: 25%;
  }

  .xl\:w-1\/2 {
    width: 50%;
  }

  .xl\:w-1\/3 {
    width: 33.3333%;
  }

  .xl\:max-w-\[150px\] {
    max-width: 150px;
  }

  .xl\:p-9 {
    padding: 2.25rem;
  }

  .xl\:p-11 {
    padding: 2.75rem;
  }

  .xl\:p-\[55px\] {
    padding: 55px;
  }

  .xl\:p-\[50px\] {
    padding: 50px;
  }

  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }

  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }

  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }

  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .xl\:pr-3 {
    padding-right: .75rem;
  }

  .xl\:pt-\[180px\] {
    padding-top: 180px;
  }

  .xl\:pb-\[160px\] {
    padding-bottom: 160px;
  }

  .xl\:text-\[45px\] {
    font-size: 45px;
  }

  .xl\:text-2xl {
    font-size: 1.5rem;
    line-height: 2rem;
  }

  .xl\:text-lg {
    font-size: 1.125rem;
    line-height: 1.75rem;
  }

  .xl\:text-3xl {
    font-size: 1.875rem;
    line-height: 2.25rem;
  }

  .xl\:leading-tight {
    line-height: 1.25;
  }

  .xl\:leading-relaxed {
    line-height: 1.625;
  }
}

@media (min-width: 1320px) {
  .\32 xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }

  .\32 xl\:mr-5 {
    margin-right: 1.25rem;
  }

  .\32 xl\:max-w-\[160px\] {
    max-width: 160px;
  }

  .\32 xl\:p-8 {
    padding: 2rem;
  }

  .\32 xl\:py-\[60px\] {
    padding-top: 60px;
    padding-bottom: 60px;
  }

  .\32 xl\:px-\[70px\] {
    padding-left: 70px;
    padding-right: 70px;
  }

  .\32 xl\:pr-5 {
    padding-right: 1.25rem;
  }

  .\32 xl\:pt-\[210px\] {
    padding-top: 210px;
  }

  .\32 xl\:pb-\[200px\] {
    padding-bottom: 200px;
  }
}

.nws-modal-overlay {
  background: #090d34b3 !important;
}

.nws-modal-wrapper .nws-modal .modal-left {
  padding-bottom: 0;
}

.footer-link {
  cursor: pointer;
  --tw-text-opacity: 1;
  color: rgb(149 156 177 / var(--tw-text-opacity));
  outline-offset: 2px;
  outline: 2px solid #0000;
  margin-bottom: 1rem;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5rem;
  display: inline-block;
}

.footer-link:hover {
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.group:hover .group-hover\:block {
  display: block;
}

.hover\:w-64:hover {
  width: 45%;
}

.filter-inherit {
  filter: invert();
}

textarea {
  resize: none !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b6cb0;
}

::-webkit-scrollbar-thumb:active {
  background: #000;
}

::-webkit-scrollbar-track {
  background: #1a202c;
  border: 0 #fff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666;
}

::-webkit-scrollbar-track:active {
  background: #333;
}

::-webkit-scrollbar-corner {
  background: none;
}

.MuiDialog-paper {
  background: #1d2244 !important;
  border-radius: 15px !important;
}

.MuiDialogTitle-root {
  line-height: initial !important;
}

.MuiInputBase-input {
  color: #eee !important;
}

.MuiSvgIcon-root, .MuiFormLabel-root, .MuiChip-label, .MuiChip-deleteIcon, .MuiIconButton-root {
  color: #9ca3af !important;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiInputBase-input::placeholder {
  color: #eee !important;
}

input[type="file"] {
  opacity: 0;
}

.MuiAutocomplete-tag {
  border: 1px solid #444 !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #bbb !important;
}

/*# sourceMappingURL=index.6ba520eb.css.map */
