.group:hover .group-hover\:block {
  display: block;
}

.hover\:w-64:hover {
  width: 45%;
}

.filter-inherit {
  filter: invert(100%);
}

textarea {
  resize: none !important;
}

::-webkit-scrollbar {
  width: 2px;
  height: 2px;
}

::-webkit-scrollbar-button {
  width: 0;
  height: 0;
}

::-webkit-scrollbar-thumb {
  background: #2d3748;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-thumb:hover {
  background: #2b6cb0;
}

::-webkit-scrollbar-thumb:active {
  background: #000000;
}

::-webkit-scrollbar-track {
  background: #1a202c;
  border: 0 none #ffffff;
  border-radius: 50px;
}

::-webkit-scrollbar-track:hover {
  background: #666666;
}

::-webkit-scrollbar-track:active {
  background: #333333;
}

::-webkit-scrollbar-corner {
  background: transparent;
}

/* MUI Material override */
.MuiDialog-paper {
  border-radius: 15px !important;
  background: #1d2244 !important;
}

.MuiDialogTitle-root {
  line-height: initial !important;
}

.MuiInputBase-input {
  color: #EEE !important;
}

.MuiSvgIcon-root {
  color: #9ca3af !important;
}

.MuiFormLabel-root, .MuiChip-label, .MuiChip-deleteIcon, .MuiIconButton-root {
  color: #9ca3af !important;
}

.MuiInputBase-root:hover .MuiOutlinedInput-notchedOutline {
  border: none !important;
}

.MuiInputBase-input::placeholder {
  color: #EEE !important;
}

input[type=file] {
  opacity: 0;
}

.MuiAutocomplete-tag {
  border: 1px solid #444 !important;
}

.MuiFormControlLabel-label.Mui-disabled {
  color: #BBB !important;
}